import React, { FC } from "react";

import { DocumentInsertMenuButton } from "../../../Common/DocumentInsertMenuButton";
import { DocumentInsertMenusWrapper } from "../../../Common/DocumentInsertMenuWrapper";

type AiRequestMenuType =
  | "createText"
  | "createTable"
  | "createCheckbox"
  | "createFigure"
  | "createExcel";

type PropsType = {
  className?: string;
  onClose: () => void;
  setSelectAiRequestMenu: (aiRequestMenu: AiRequestMenuType) => void;
  aiRequestMenu: AiRequestMenuType;
};

export const DocumentInsertMenus: FC<PropsType> = ({
  className = "",
  onClose,
  setSelectAiRequestMenu,
  aiRequestMenu,
}: PropsType) => {
  return (
    <DocumentInsertMenusWrapper onClose={onClose} className={className}>
      <DocumentInsertMenuButton
        title="文章を作成"
        icon="tbPencilPlus"
        className={`${aiRequestMenu === "createText" ? "bg-primary-50" : ""}`}
        setSelectAiRequestMenu={() => setSelectAiRequestMenu("createText")}
      />
      <DocumentInsertMenuButton
        title="Excelシートから文章作成"
        icon="faTable"
        className={`${aiRequestMenu === "createExcel" ? "bg-primary-50" : ""}`}
        setSelectAiRequestMenu={() => {
          setSelectAiRequestMenu("createExcel");
          onClose();
        }}
      />
      <DocumentInsertMenuButton
        title="表を作成"
        icon="aiOutlineTable"
        className={`${aiRequestMenu === "createTable" ? "bg-primary-50" : ""}`}
        setSelectAiRequestMenu={() => {
          setSelectAiRequestMenu("createTable");
          onClose();
        }}
      />
      <DocumentInsertMenuButton
        title="チェックリストを作成"
        icon="bsListCheck"
        className={`${
          aiRequestMenu === "createCheckbox" ? "bg-primary-50" : ""
        }`}
        setSelectAiRequestMenu={() => {
          setSelectAiRequestMenu("createCheckbox");
          onClose();
        }}
      />
      <DocumentInsertMenuButton
        title="ポンチ絵・図の作成"
        icon="biPalette"
        className={`${aiRequestMenu === "createFigure" ? "bg-primary-50" : ""}`}
        setSelectAiRequestMenu={() => {
          setSelectAiRequestMenu("createFigure");
          onClose();
        }}
      />
    </DocumentInsertMenusWrapper>
  );
};
