import React from "react";
import { toast } from "react-toastify";

import { Button } from "@spesill/components/atoms";

import { LearningDatabaseWithUser } from "@spesill/hooks/firestore/learningDatabases/useLearningDatabasesWithUser";
enum ActiveTab {
  LearningDatabase,
  ChatGPT,
}
interface TabSelectorProps {
  activeTab: ActiveTab;
  selectedLearningDatabase?: LearningDatabaseWithUser;
  setActiveTab: (tab: ActiveTab) => void;
  setUpdateChatLearningDatabaseModalOpen: () => void;
}

export const TabSelector: React.FC<TabSelectorProps> = ({
  activeTab,
  selectedLearningDatabase,
  setActiveTab,
  setUpdateChatLearningDatabaseModalOpen,
}) => {
  const handleDatabaseClick = () => {
    if (selectedLearningDatabase) {
      setActiveTab(ActiveTab.LearningDatabase);
    } else {
      toast.warn("データベースを選択してください");
    }
  };

  return (
    <div className="flex flex-row justify-between items-center p-2 bg-blueGray-50">
      <div className="flex flex-row justify-between items-center space-x-1 bg-blueGray-50">
        <Button
          className={`${
            activeTab === ActiveTab.LearningDatabase
              ? "bg-blueGray-500 text-white"
              : "text-blueGray-900"
          }`}
          icon={
            selectedLearningDatabase
              ? {
                  icon:
                    selectedLearningDatabase?.structureType === "table"
                      ? "bsTable"
                      : "ioDocumentOutline",
                  color: "text-blueGray-500",
                  className: `w-5 h-5 ${
                    activeTab === ActiveTab.LearningDatabase
                      ? "bg-white rounded"
                      : ""
                  } ${
                    selectedLearningDatabase?.structureType === "table"
                      ? "p-1"
                      : "p-0.5"
                  }`,
                }
              : undefined
          }
          text={selectedLearningDatabase?.systemName ?? "選択中のデータベース"}
          onClick={handleDatabaseClick}
          color="gray"
          variant="text"
        />
        <Button
          className={`${
            activeTab === ActiveTab.ChatGPT
              ? "bg-blueGray-500 text-white"
              : "text-blueGray-900"
          }`}
          text="ChatGPT"
          onClick={() => setActiveTab(ActiveTab.ChatGPT)}
          color="gray"
          variant="text"
        />
      </div>
      <Button
        text="切り替え"
        variant="text"
        className="text-sm"
        color="primary"
        icon={{
          icon: "aiOutlineReload",
          size: "1rem",
          color: "text-primary-400",
        }}
        onClick={setUpdateChatLearningDatabaseModalOpen}
      />
    </div>
  );
};
