import axios from "axios";
import { useCallback, useEffect, useState } from "react";

import { useCurrentUser } from "@spesill/hooks";
import { User } from "@spesill/models";
import { LearningDatabase } from "@spesill/models";
import { LearningDatabaseRepository } from "@spesill/repository/db/learningDatabase.repository";
import { UserRepository } from "@spesill/repository/db/user.repository";

import type { OrderByDirection } from "@spesill/libs/firebase";

const userRepository = new UserRepository();
const learningDatabaseRepository = new LearningDatabaseRepository();

export type LearningDatabaseWithUser = LearningDatabase & { user?: User };

type FilterType = {
  createUserId?: string;
};

type SortType = {
  field: "updatedAt";
  order: OrderByDirection;
};

export const useLearningDatabasesWithUser = (isFreePlan?: boolean) => {
  const { currentUser } = useCurrentUser();
  const [learningDatabases, setLearningDatabases] = useState<
    LearningDatabaseWithUser[]
  >([]);
  const [selectedFilter, setSelectedFilter] = useState<FilterType>();
  const [sort, setSort] = useState<SortType>({
    field: "updatedAt",
    order: "desc",
  });

  const fetchLearningDatabasesAndUsers = useCallback(async () => {
    if (!currentUser?.tenantId) return;

    const users = await userRepository.findByTenantId(currentUser.tenantId);
    const docs = isFreePlan
      ? await learningDatabaseRepository.findByType("DEFAULT")
      : await learningDatabaseRepository.findByTenantId(
          currentUser.tenantId,
          {
            createUserId: selectedFilter?.createUserId,
          },
          sort,
        );

    const docsWithUser = docs
      .filter((doc) => !doc.deletedAt)
      .map((doc) => {
        const user = users.find((user) => user.id === doc.createUserId);
        return Object.defineProperty(doc, "user", {
          value: user,
          writable: true,
          enumerable: true,
          configurable: true,
        });
      });
    setLearningDatabases(docsWithUser);
  }, [currentUser?.tenantId, isFreePlan, selectedFilter?.createUserId, sort]);

  useEffect(() => {
    fetchLearningDatabasesAndUsers();
  }, [currentUser?.tenantId, fetchLearningDatabasesAndUsers]);

  const logicalDeleteLearningDatabase = useCallback(
    async (id: string) => {
      if (!currentUser?.tenantId) return;

      await learningDatabaseRepository.logicalDeleteById(id);

      await fetchLearningDatabasesAndUsers();
      await axios.post("/api/mail/notification", {
        notificationType: "delete-LearningDatabase",
        systemName: (
          learningDatabases.find((db) => db.id === id) as LearningDatabase
        ).systemName,
        operatorUserId: currentUser.id,
        resourceId: id,
      });
    },
    [currentUser, fetchLearningDatabasesAndUsers, learningDatabases],
  );

  const selectCreateUserId = (createUserId: string) => {
    setSelectedFilter({ createUserId });
  };

  const resetFilterCreateUserId = () => {
    setSelectedFilter({ createUserId: undefined });
  };

  const changeSortOrderDirection = (order: OrderByDirection) => {
    setSort((prev) => ({ ...prev, order }));
  };

  return {
    learningDatabases,
    selectedFilter,
    sort,
    fetchLearningDatabasesAndUsers,
    logicalDeleteLearningDatabase,
    selectCreateUserId,
    resetFilterCreateUserId,
    changeSortOrderDirection,
  };
};
