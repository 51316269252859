import React, { FC, FormEvent, MouseEvent, useMemo } from "react";
import { toast } from "react-toastify";

import Link from "next/link";

import {
  Button,
  DropdownField,
  FormSubmitButton,
  Icon,
  IconType,
  TextLoadingAnimation,
  Tooltip,
} from "@spesill/components/atoms";
import {
  CreateEvaluateDatabaseModal,
  MarkdownRenderer,
} from "@spesill/components/organisms";

import {
  useBoolean,
  useCurrentUser,
  useEvaluateDatabaseDropdownField,
} from "@spesill/hooks";
import { CorrectionType } from "@spesill/hooks/aiRequest/useEvaluateRequest";
import { Document } from "@spesill/models";

type Props = {
  className?: string;
  document: Document;
  setCurrentMenu: (menu: "") => void;
  review?: string;
  suggestions?: string[];
  corrections?: CorrectionType[];
  requestEvaluate?: (databaseId: string) => void;
  isEvaluating?: boolean;
  onReflectCorrection?: (correction: CorrectionType) => void;
  onCancelCorrection?: (correction: CorrectionType) => void;
  onFocusCorrection?: (correction: CorrectionType) => void;
};

const LoadingComponent = ({
  title,
  icon,
}: {
  title: string;
  icon: IconType;
}) => {
  return (
    <>
      <p className="flex flex-row gap-x-2 align-center justify-center">
        <Icon icon={icon} size="2rem" color="text-primary-400" />

        <span className="text-h6 text-primary-400">{title}</span>
      </p>
      <div className="px-6 py-4 bg-blueGray-0 rounded-md whitespace-pre-wrap text-sm max-h-96 overflow-y-auto">
        <TextLoadingAnimation />
      </div>
    </>
  );
};

export const AiSentenceCheckMenu: FC<Props> = ({
  className = "",
  setCurrentMenu,
  review,
  suggestions,
  corrections,
  requestEvaluate,
  isEvaluating,
  onCancelCorrection,
  onReflectCorrection,
  onFocusCorrection,
}) => {
  const { currentUser, currentPlan } = useCurrentUser();
  const isFreePlan = useMemo(() => currentPlan === "FREE", [currentPlan]);
  const {
    isChecked: isCreateEvaluateDatabaseModalOpen,
    setTrue: setCreateEvaluateDatabaseModalOpen,
    setFalse: setCreateEvaluateDatabaseModalClose,
  } = useBoolean(false);
  const {
    dropdownValue: dropdownValueEvaluateDatabase,
    dropdownSelectableValue: dropdownSelectableValueEvaluateDatabase,
    onChange: onChangeDropdownEvaluateDatabase,
    fetchEvaluateDatabases: refreshOptionsEvaluateDatabase,
  } = useEvaluateDatabaseDropdownField();
  const handleOnClose = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setCurrentMenu("");
  };

  const handleOnSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!currentUser) return;
    if (!dropdownValueEvaluateDatabase) {
      toast.error("評価データベースを選択してください。");
      return;
    }
    requestEvaluate &&
      requestEvaluate(dropdownValueEvaluateDatabase?.value || "");
  };

  return (
    <>
      <form
        className={`p-6 flex flex-col border-b border-solid border-blueGray-50 h-full overflow-scroll ${className}`}
        onSubmit={handleOnSubmit}
      >
        <div className="flex flex-col grow gap-y-10">
          <div className="flex flex-col gap-y-6">
            <p className="text-body-base">
              文章チェックするための評価データベースをセットアップしてください。
            </p>
            <div className="flex w-full">
              <DropdownField
                name="newEvaluateDatabaseID"
                value={dropdownValueEvaluateDatabase}
                options={dropdownSelectableValueEvaluateDatabase}
                onChange={(newValue, meta) => {
                  if (meta.action !== "create-option" && newValue) {
                    onChangeDropdownEvaluateDatabase(newValue);
                  }
                }}
                height="47px"
                width="16rem"
                isClearable={false}
                placeholder="評価データベースを選択"
                customMenuPosition="bottom"
                customMenu={() =>
                  isFreePlan ? (
                    <Tooltip
                      direction="right"
                      className="text-sm"
                      contentChildren={
                        <div>
                          現在のプランではご利用できません。
                          <br />
                          プランの切り替えは
                          <Link className="border-b" href={"/settings/plan"}>
                            こちら
                          </Link>
                        </div>
                      }
                    >
                      <Button
                        text="データベースを新規作成"
                        color="primary"
                        variant="text"
                        className="text-body-base w-full"
                        icon={{
                          icon: "aiOutlinePlus",
                          size: "1rem",
                          color: "text-primary-400",
                        }}
                        disabled
                        onClick={() => 0}
                      />
                    </Tooltip>
                  ) : (
                    <Button
                      text="データベースを新規作成"
                      color="primary"
                      variant="text"
                      className="text-body-base w-full"
                      icon={{
                        icon: "aiOutlinePlus",
                        size: "1rem",
                        color: "text-primary-400",
                      }}
                      onClick={() => {
                        setCreateEvaluateDatabaseModalOpen();
                      }}
                    />
                  )
                }
              />
              <FormSubmitButton
                text={isEvaluating ? "評価中..." : "評価開始"}
                color="primary"
                variant="contained"
                disabled={isEvaluating}
                className="ml-4"
              />
            </div>
          </div>

          <div className="flex flex-col gap-y-6">
            {review ? (
              <p className="flex flex-col gap-y-6">
                <p className="flex flex-row gap-x-2 align-center justify-center">
                  <Icon
                    icon="aiOutlineCheckCircle"
                    size="2rem"
                    color="text-primary-400"
                  />

                  <span className="text-h6 text-primary-400">AI評価</span>
                </p>

                <div className="px-6 py-4 bg-blueGray-0 rounded-md whitespace-pre-wrap max-h-96 overflow-y-auto">
                  <MarkdownRenderer text={review} />
                </div>
              </p>
            ) : (
              isEvaluating && (
                <LoadingComponent title={"AI評価"} icon={"aiOutlineFileText"} />
              )
            )}

            {suggestions && suggestions.length > 0 ? (
              <div className="flex flex-col gap-y-2">
                <p className="flex flex-row gap-x-2 align-center justify-center">
                  <Icon
                    icon="mdOutlineComment"
                    size="2rem"
                    color="text-primary-400"
                  />

                  <span className="text-h6 text-primary-400">改善提案</span>
                </p>
                <div className="max-h-96 overflow-y-auto space-y-4">
                  {suggestions.map((suggestion: string, index: number) => (
                    <p
                      key={index}
                      className="px-6 py-4 bg-blueGray-0 rounded-md shadow-sm whitespace-pre-wrap"
                    >
                      <MarkdownRenderer text={suggestion} />
                    </p>
                  ))}
                </div>
              </div>
            ) : (
              isEvaluating && (
                <LoadingComponent
                  title={"改善提案"}
                  icon={"mdOutlineComment"}
                />
              )
            )}
            {corrections && corrections?.length > 0 ? (
              <p className="flex flex-col gap-y-6">
                <p className="flex flex-row gap-x-2 align-center justify-center">
                  <Icon
                    icon="mdSpellcheck"
                    size="2rem"
                    color="text-primary-400"
                  />
                  <span className="text-h6 text-primary-400">校正</span>
                </p>

                <div className="max-h-96 overflow-y-auto">
                  {corrections.map((correctedDocument, index) => {
                    return (
                      <div
                        key={index}
                        className="px-6 py-4 mt-4 space-y-2 bg-blueGray-0 rounded-md whitespace-pre-wrap relative"
                      >
                        <button
                          type="button"
                          className="font-bold text-blueGray-600 rounded hover:bg-blueGray-100 py-1 px-0.5"
                          onClick={() => {
                            onFocusCorrection &&
                              onFocusCorrection(correctedDocument);
                          }}
                        >
                          <div className="flex gap-x-2">
                            <div className="bg-blueGray-400 opacity-80 rounded-full w-6 h-6 flex items-center justify-center text-white text-xs font-bold">
                              {correctedDocument.index + 1}
                            </div>
                            {correctedDocument.location?.cellText && (
                              <Tooltip
                                direction={"bottom"}
                                text={"校正箇所に移動"}
                                className="text-xs"
                              >
                                <>
                                  {correctedDocument.location.sheet &&
                                    correctedDocument.location.sheet + " / "}
                                  {correctedDocument.location.cellText}
                                </>
                              </Tooltip>
                            )}
                          </div>
                        </button>
                        <div className="py-3">
                          <MarkdownRenderer
                            text={correctedDocument.suggestionMarkdown}
                          />
                        </div>
                        <div className="flex justify-end space-x-2 text-sm mt-4">
                          <Button
                            text={"キャンセル"}
                            color={"gray"}
                            variant={"contained"}
                            size={"small"}
                            className="border border-solid border-blueGray-50"
                            onClick={(e) => {
                              e.preventDefault();
                              onCancelCorrection &&
                                onCancelCorrection(correctedDocument);
                            }}
                          />
                          <Button
                            text={"置き換え"}
                            color={"primary"}
                            variant={"text"}
                            size={"small"}
                            className="border border-solid border-primary-400"
                            onClick={(e) => {
                              e.preventDefault();
                              onReflectCorrection &&
                                onReflectCorrection(correctedDocument);
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </p>
            ) : (
              isEvaluating && (
                <LoadingComponent title={"校正"} icon={"mdSpellcheck"} />
              )
            )}
          </div>
        </div>

        <div className="border-t border-solid border-blueGray-50 pt-4 mt-4 flex justify-end gap-x-4">
          <Button
            text="キャンセル"
            color="gray"
            variant="contained"
            onClick={handleOnClose}
          />
        </div>
      </form>
      {isCreateEvaluateDatabaseModalOpen && (
        <CreateEvaluateDatabaseModal
          onClose={setCreateEvaluateDatabaseModalClose}
          onSuccess={async (id) => {
            const res = await refreshOptionsEvaluateDatabase();
            if (!res) return;
            const item = res.find((e) => e.id === id);
            if (!item) return;
            onChangeDropdownEvaluateDatabase({
              value: item.id,
              label: item.systemName,
            });
          }}
        />
      )}
    </>
  );
};
