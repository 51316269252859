import { Editor, NodeViewProps } from "@tiptap/react";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";

import { apiClient } from "@spesill/libs/apiClient";
import { CorrectResponseType } from "@spesill/models/api/evaluate_spec";

import { useIncrementAiUsage } from "../firestore/tenantAiUsages/useIncrementAiUsage";
import { useTenantAiCallRestriction } from "../firestore/tenants/useTenantAiCallRestriction";
import { useArray } from "../useArray";
import { useBoolean } from "../useBoolean";

type excelLocationType = {
  row: number;
  col: number;
  cellText?: string;
  sheet?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any; // GC.Spread.Sheets.Styleが正しい型だが、ライセンスの認証を行わないとエラーになるため、anyにしている
};

type locationType = excelLocationType;

export type CorrectionType = {
  // type: "excel" | "pdf" | "word";
  suggestionMarkdown: string;
  originText: string;
  replacingText: string;
  reference: string;
  location?: locationType;
  index: number;
};

export const useEvaluateRequest = (
  editor: Editor | null,
  tenantId: string,
  documentPath: string,
) => {
  const [review, setReview] = useState<string>("");
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const {
    items: corrections,
    setItems: setCorrections,
    findAndRemove: removeCorrection,
  } = useArray<CorrectionType>();
  const {
    isChecked: isEvaluating,
    setTrue: setEvaluateTrue,
    setFalse: setEvaluateFalse,
  } = useBoolean(false);
  const { incrementAiCallCount } = useIncrementAiUsage();

  const { checkCanCallAi } = useTenantAiCallRestriction();

  const requestEvaluate = async (
    databaseId: string,
    docType: "word" | "excel" = "word",
  ) => {
    setEvaluateTrue();
    try {
      const canCall = await checkCanCallAi();
      if (!canCall) {
        toast.error(
          "AIの月間利用回数上限に達しています。プランをアップグレードしてください。",
        );
        setEvaluateFalse();
        return;
      }
      const res = await apiClient().evaluate_spec.$post({
        body: {
          tenant_id: tenantId,
          group_id: databaseId,
          spec_path: documentPath,
        },
      });

      incrementAiCallCount("evaluateSpec");
      setReview(res.review);
      setSuggestions(res.suggestions);
      const resCorrections = handleCorrections(res.corrections, docType);
      return {
        review: res.review,
        suggestions: res.suggestions,
        corrections: resCorrections,
      };
    } catch (error) {
      toast.error(
        "AI文書チェックのリクエストに失敗しました。しばらく時間をおいてから再度お試しください。",
      );
      console.error(error);
    } finally {
      setEvaluateFalse();
    }
  };

  function handleCorrections(
    correctResponses: CorrectResponseType[],
    docType: "word" | "excel" | "pdf" = "word",
  ) {
    if (docType === "word") {
      convertToProofReaderExtension(correctResponses);
      return;
    } else if (docType === "excel") {
      const resCorrections = createCorrectionsFromResponse(correctResponses);
      setCorrections(resCorrections);
      return resCorrections;
    } else if (docType === "pdf") {
      const resCorrections = createCorrectionsFromResponse(correctResponses);
      setCorrections(resCorrections);
      return resCorrections;
    } else {
      throw new Error("Invalid docType");
    }
  }

  function createCorrectionsFromResponse(
    correctResponses: CorrectResponseType[],
  ) {
    return correctResponses.map((correctResponse, index) => {
      const suggestionMarkdown = correctResponse.suggestion;
      const originText = correctResponse.originalText;
      const replacingText = correctResponse.suggestion
        .replace(/~~[^~]+~~/g, "")
        .replace(/\*\*/g, "");
      const reference = correctResponse.originalText;
      return {
        suggestionMarkdown,
        originText,
        replacingText,
        reference,
        index,
      };
    });
  }

  const findProofReaders = useCallback(() => {
    if (!editor) return [];

    const proofReaders: { node: NodeViewProps["node"]; position: number }[] =
      [];

    editor.state.doc.descendants((node, pos) => {
      if (node.type.name === "proofReader") {
        proofReaders.push({ node, position: pos });
      }
    });

    return proofReaders;
  }, [editor]);

  const replaceText = (
    text: string,
    reference: string,
    replacingText: string,
  ) => {
    return text.replace(new RegExp(reference, "gi"), replacingText);
  };

  const onReflectCorrection = (correction: CorrectionType) => {
    if (!editor) return;
    const proofReaders = findProofReaders();
    const proofReader = proofReaders.find(
      (pr) => pr.node.attrs.markdownText === correction.suggestionMarkdown,
    );

    if (!proofReader) return;
    const from = proofReader.position;
    const to = from + proofReader.node.nodeSize;
    const newText = replaceText(
      proofReader.node.attrs.text,
      correction.reference,
      correction.replacingText,
    );

    editor
      .chain()
      .focus()
      .deleteRange({ from, to })
      .insertContentAt({ from, to: from }, newText)
      .run();
    const newCorrections = corrections?.filter(
      (c) => c.suggestionMarkdown !== correction.suggestionMarkdown,
    );
    removeCorrection(
      (item: CorrectionType) => item.reference === correction.reference,
    );
    setCorrections(newCorrections);
  };

  const onCancelCorrection = (correction: CorrectionType) => {
    if (!editor) return;
    const proofReaders = findProofReaders();
    const proofReader = proofReaders.find(
      (pr) => pr.node.attrs.markdownText === correction.suggestionMarkdown,
    );

    if (!proofReader) return;
    const from = proofReader.position;
    const to = from + proofReader.node.nodeSize;

    editor
      .chain()
      .focus()
      .deleteRange({ from, to })
      .insertContentAt({ from, to: from }, proofReader.node.attrs.text)
      .run();
    removeCorrection(
      (item: CorrectionType) => item.reference === correction.reference,
    );
  };

  type changesType = {
    from: number;
    to: number;
    correction: CorrectionType;
  };

  function convertToProofReaderExtension(
    correctSuggestions: CorrectResponseType[],
  ) {
    if (!editor) return;

    const correctionMapper = createCorrectionsFromResponse(correctSuggestions);
    const results: CorrectionType[] = [];
    const changes = collectTextCorrections(correctionMapper);

    applyTextCorrections(changes, results, correctionMapper);

    console.log("results", results);
    // posの降順になっているので、逆順にする
    setCorrections(results.reverse());
  }

  function collectTextCorrections(correctionMapper: CorrectionType[]) {
    const changes: { from: number; to: number; correction: CorrectionType }[] =
      [];

    editor?.view.state.doc.descendants((node, pos) => {
      if (node.isText) {
        const nodeText = node.text;
        correctionMapper.forEach((correction) => {
          if (
            nodeText &&
            (nodeText.includes(correction.originText) ||
              nodeText === correction.originText)
          ) {
            const from = pos + nodeText.indexOf(correction.originText);
            const to = from + correction.originText.length;

            changes.push({
              from,
              to,
              correction: {
                suggestionMarkdown: correction.suggestionMarkdown,
                originText: correction.originText,
                replacingText: correction.replacingText,
                reference: correction.reference,
                index: correction.index,
              },
            });
          }
        });
      }
    });

    // 位置の変更を逆順に処理して、位置ずれを防ぐ
    changes.sort((a, b) => b.from - a.from);

    return changes;
  }

  function applyTextCorrections(
    changes: changesType[],
    results: CorrectionType[],
    correctionMapper: CorrectionType[],
  ) {
    changes.forEach(({ from, to, correction }) => {
      const text = correction.originText;

      const result = editor
        ?.chain()
        .deleteRange({ from, to })
        .insertContentAt(
          { from, to: from },
          {
            type: "proofReader",
            attrs: {
              text,
              markdownText: correction.suggestionMarkdown,
              replacingText: correction.replacingText,
              correction: correctionMapper,
              reference: correction.reference,
              removeCorrection: removeCorrection,
              index: correction.index,
            },
          },
        )
        .run();

      if (result) {
        results.push({
          suggestionMarkdown: correction.suggestionMarkdown,
          originText: correction.originText,
          replacingText: correction.replacingText,
          reference: correction.reference,
          index: correction.index,
        });
      }
    });
  }

  return {
    review,
    suggestions,
    corrections,
    isEvaluating,
    requestEvaluate,
    convertToProofReaderExtension,
    onReflectCorrection,
    onCancelCorrection,
  };
};
