import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";

import { ButtonWithTextArea } from "@spesill/components/molecules";
import {
  MessageBox,
  UpdateChatLearningDatabaseModal,
} from "@spesill/components/organisms";

import {
  useBoolean,
  useChatRoom,
  useLearningDatabasesWithUser,
  useChatRoomMessages,
  useInput,
  useCurrentUser,
  useIncrementAiUsage,
} from "@spesill/hooks";
import { sseClient } from "@spesill/libs/sseClient";
import { ChatRoomMessage } from "@spesill/models";

import { TabSelector } from "./ TabSelector ";
import ChatDetailSuggestions from "./Suggestions";
import TemplateSelector from "./TemplateSelector";
import SuggestionButton from "../SuggestionButton";

type PropsType = {
  chatId: string;
};

enum ActiveTab {
  LearningDatabase,
  ChatGPT,
}
export const ChatDetail: React.FC<PropsType> = ({ chatId }) => {
  const { currentUser } = useCurrentUser();
  const [{ value, onChange, resetValue, setValue }] = useInput();
  const { learningDatabases } = useLearningDatabasesWithUser();
  const { fetchChatRoom, chatRoom } = useChatRoom(chatId);
  const { incrementAiCallCount } = useIncrementAiUsage();
  const { chatRoomMessages, fetchChatRoomMessages } =
    useChatRoomMessages(chatId);
  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.ChatGPT);

  const [messages, setMessages] = useState<ChatRoomMessage[]>([]);
  const [streamingMessage, setStreamingMessage] =
    useState<ChatRoomMessage | null>(null);

  const {
    isChecked: isUpdateChatLearningDatabaseModal,
    setTrue: setUpdateChatLearningDatabaseModalOpen,
    setFalse: setUpdateChatLearningDatabaseModalClose,
  } = useBoolean(false);

  const { isChecked: isStreaming, setTrue, setFalse } = useBoolean(false);

  const onSendMessage = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!currentUser || !chatRoom) return;

    if (!value) {
      toast.error("メッセージを入力してください");
      return;
    }

    const newMessage = {
      id: "streaming",
      sender: "USER",
      message: value,
      createdAt: new Date(),
      updatedAt: new Date(),
    } as ChatRoomMessage;

    setMessages((prev) => [...prev, newMessage]);
    setTrue();
    resetValue();

    try {
      await sseClient.streamResponse(
        "ai_chat",
        {
          user_id: currentUser.id,
          chat_room_id: chatRoom.id,
          tenant_id: currentUser.tenantId,
          group_id:
            activeTab === ActiveTab.LearningDatabase
              ? chatRoom.learningDatabaseId || ""
              : "",
          question: value,
        },
        (event) => {
          setStreamingMessage(
            (prev) =>
              ({
                id: "streaming",
                sender: "AI",
                message: prev ? prev.message + event.data : event.data,
                createdAt: new Date(),
                updatedAt: new Date(),
              }) as ChatRoomMessage,
          );
        },
        (error) => {
          console.error("ストリーミングエラー:", error);
          toast.error("メッセージの受信中にエラーが発生しました");
          setFalse();
          setMessages((prev) => prev.slice(0, -1));
        },
      );

      incrementAiCallCount("aiChat");
      fetchChatRoomMessages();
    } catch (error) {
      console.error("API呼び出しエラー:", error);
      toast.error("メッセージの送信中にエラーが発生しました");
      setFalse();
      setMessages((prev) => prev.slice(0, -1));
    } finally {
      setFalse();
      setStreamingMessage(null);
    }
  };

  const selectedLearningDatabase = useMemo(
    () =>
      learningDatabases.find(
        (learningDatabase) =>
          learningDatabase.id === chatRoom?.learningDatabaseId,
      ),
    [learningDatabases, chatRoom],
  );

  const isSelectingTable = useMemo(
    () =>
      activeTab === ActiveTab.LearningDatabase &&
      selectedLearningDatabase?.structureType === "table",
    [activeTab, selectedLearningDatabase],
  );

  useEffect(() => {
    setMessages(chatRoomMessages);
  }, [chatRoomMessages]);

  return (
    <div className="w-full flex flex-col h-full max-h-screen">
      <div className="h-full flex flex-col p-4 overflow-auto">
        <ul className="w-full h-full pb-20">
          {messages.length === 0 && (
            <MessageBox
              isMe={false}
              isInitial={true}
              message="こんにちは。このチャットではChat-GPTもしくはデータベースに蓄積された文書データをもとに、AIがあなたの課題や知識の抽出をサポートします。"
            />
          )}
          {messages.map((chatRoomMessage) => (
            <MessageBox
              key={chatRoomMessage.id}
              isMe={chatRoomMessage.sender === "USER"}
              lastName={currentUser?.lastName}
              message={chatRoomMessage.message}
              sources={chatRoomMessage.sources}
            />
          ))}
          {isStreaming && (
            <MessageBox
              isMe={false}
              message={streamingMessage?.message || ""}
              isStreaming={isStreaming}
            />
          )}
        </ul>
        {messages.length === 0 && isSelectingTable && (
          <div className="flex flex-col gap-3 pb-6">
            <SuggestionButton
              onClick={() =>
                setValue(
                  "<項目名>に〇〇が含まれているデータの<項目名>を抽出して下さい",
                )
              }
            >
              <span className="text-primary-400">{"<項目名>"}</span>
              に〇〇が含まれているデータの
              <span className="text-primary-400">{"<項目名>"}</span>
              を抽出して下さい
            </SuggestionButton>
            <SuggestionButton
              onClick={() => setValue("<項目名>が〇〇の<項目名>を教えて下さい")}
            >
              <span className="text-primary-400">{"<項目名>"}</span>
              が〇〇の
              <span className="text-primary-400">{"<項目名>"}</span>
              を教えて下さい
            </SuggestionButton>
          </div>
        )}
      </div>
      <div className="flex flex-col sticky bottom-0 bg-white">
        <TabSelector
          activeTab={activeTab}
          selectedLearningDatabase={selectedLearningDatabase}
          setActiveTab={setActiveTab}
          setUpdateChatLearningDatabaseModalOpen={
            setUpdateChatLearningDatabaseModalOpen
          }
        />
        <form className="p-4">
          <ButtonWithTextArea
            name="question"
            type="text"
            icon="ioMdSend"
            className="border-none bg-white outline-0"
            placeholder="メッセージを入力してください"
            required
            disabledButton={!value || isStreaming}
            value={value}
            outline={false}
            onChange={onChange}
            onClick={onSendMessage}
            rows={1}
            maxRows={15}
          />
          {isSelectingTable && (
            <TemplateSelector
              isSelectingTable={isSelectingTable}
              messages={messages}
              setValue={setValue}
            />
          )}
          {selectedLearningDatabase &&
            activeTab === ActiveTab.LearningDatabase && (
              <ChatDetailSuggestions
                tenantId={currentUser?.tenantId}
                groupId={selectedLearningDatabase?.id}
                setValue={setValue}
              />
            )}
        </form>
      </div>
      {isUpdateChatLearningDatabaseModal && chatRoom && (
        <UpdateChatLearningDatabaseModal
          onClose={setUpdateChatLearningDatabaseModalClose}
          chatRoom={chatRoom}
          onSuccess={() => {
            fetchChatRoom();
            setActiveTab(ActiveTab.LearningDatabase);
          }}
        />
      )}
    </div>
  );
};
