import React from "react";
import { toast } from "react-toastify";

import Image from "next/image";

import {
  DotLoadingAnimation,
  InitialNameIcon,
} from "@spesill/components/atoms";
import { IconButton } from "@spesill/components/molecules";

import { SourceType } from "@spesill/models/source";

import { MarkdownRenderer } from "../..";
import { DocumentSource } from "../../Document/ConvertedHTMLDetail/DocumentHeadingTitleForm/DocumentInsertTextForm/DocumentSource";

type PropsType = {
  isMe?: boolean;
  isStreaming?: boolean;
  lastName?: string;
  message: string;
  sources?: SourceType[];
  isInitial?: boolean;
};

export const MessageBox = ({
  isMe = false,
  isInitial = false,
  isStreaming = false,
  message,
  lastName = "",
  sources,
}: PropsType) => {
  const handleCopy = () => {
    navigator.clipboard
      .writeText(message || "")
      .then(() => toast.success("テキストをコピーしました"))
      .catch(() => toast.error("コピーに失敗しました"));
  };

  return (
    <li className="flex flex-row gap-2 items-start w-full text-sm">
      {isMe ? (
        <InitialNameIcon className="shrink-0 w-10 h-10" name={lastName} />
      ) : (
        <Image
          src="/logo-circle.png"
          alt="SPESILL LOGO"
          width="40"
          height="40"
          className={`${isStreaming ? "animate-bounce" : ""}`}
        />
      )}

      <div
        className={`p-6 rounded-r-lg rounded-bl-lg w-full mt-5 whitespace-pre-wrap ${
          isMe ? "bg-white" : "bg-primary-50"
        }`}
      >
        <MarkdownRenderer text={message} />
        {isStreaming && message.length === 0 && <DotLoadingAnimation />}
        {isStreaming && message.length > 0 && (
          <span className="inline-block animate-pulse">●</span>
        )}
        {/* ストリーミング中でない場合は、ソースを表示 */}
        {!isStreaming && sources && sources?.length > 0 && (
          <DocumentSource sources={sources} className="mt-4" />
        )}
        {/* ストリーミング中でない場合は、コピーボタンを表示 */}
        {!isMe && !isInitial && !isStreaming && (
          <div className="flex items-end justify-end">
            <IconButton
              icon={{
                icon: "mdContentCopy",
                size: "1.25rem",
                color: "text-blueGray-500",
              }}
              onClick={handleCopy}
              tooltip={{
                text: "コピーする",
                direction: "top",
                className: "text-xs",
              }}
            />
          </div>
        )}
      </div>
    </li>
  );
};
