import React, { FC } from "react";

import { ModalWrapper } from "@spesill/components/molecules";

type PropsType = {
  className?: string;
  onClose: () => void;
  children: React.ReactNode;
};

export const DocumentInsertMenusWrapper: FC<PropsType> = ({
  className = "",
  onClose,
  children,
}: PropsType) => {
  return (
    <>
      <ModalWrapper onClose={onClose} isWhite />
      <ul
        className={`rounded  absolute top-10 right-0 z-20 bg-white p-2 body-base gap-y-2 w-fit ${className}`}
        contentEditable={false}
      >
        {children}
      </ul>
    </>
  );
};
