import { Node, mergeAttributes } from "@tiptap/core";
import {
  NodeViewWrapper,
  ReactNodeViewRenderer,
  Editor,
  NodeViewProps,
} from "@tiptap/react";
import React from "react";

import { Button } from "@spesill/components/atoms";
import { MarkdownRenderer } from "@spesill/components/organisms/MarkdownRenderer/MarkdownRenderer";

import { CorrectionType } from "@spesill/hooks/aiRequest/useEvaluateRequest";

export const ProofReaderExtension = Node.create({
  name: "proofReader",
  group: "inline",
  inline: true,
  atom: true,

  addAttributes() {
    return {
      text: {
        default: null,
      },
      markdownText: {
        default: null,
      },
      replacingText: {
        default: null,
      },
      reference: {
        default: null,
      },
      correction: {
        default: null,
      },
      removeCorrection: {
        default: null,
      },
      index: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "span[proof-reader]",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["span", mergeAttributes(HTMLAttributes, { "proof-reader": "" })];
  },

  addNodeView() {
    return ReactNodeViewRenderer(CustomExtensionComponent);
  },
});

type PropsType = {
  node: NodeViewProps["node"];
  editor: Editor;
  getPos: NodeViewProps["getPos"];
};

const CustomExtensionComponent = ({ node, editor, getPos }: PropsType) => {
  const handleSave = () => {
    const pos = getPos();
    const newText = replaceText(
      node.attrs.text,
      node.attrs.reference,
      node.attrs.replacingText,
    );

    node.attrs.removeCorrection(
      (item: CorrectionType) => item.reference === node.attrs.reference,
    );
    editor
      .chain()
      .focus()
      .deleteRange({ from: pos, to: pos + node.nodeSize })
      .insertContent(newText)
      .run();
  };

  const handleCancel = () => {
    const pos = getPos();
    node.attrs.removeCorrection(
      (item: CorrectionType) => item.reference === node.attrs.reference,
    );
    editor
      .chain()
      .focus()
      .deleteRange({ from: pos, to: pos + node.nodeSize })
      .insertContent(node.attrs.text)
      .run();
  };

  const highlightReference = (text: string, reference: string) => {
    if (!reference) {
      // referenceがnullの場合は早期リターン
      return <span>{text}</span>;
    }
    const parts = text.split(new RegExp(`(${reference})`, "gi"));
    return parts.map((part, i) =>
      part.toLowerCase() === reference.toLowerCase() ? (
        <span key={i} className="">
          <MarkdownRenderer text={node.attrs.markdownText} />
        </span>
      ) : (
        <span key={i}>{part}</span>
      ),
    );
  };

  // referenceをreplacingTextで置き換える関数
  const replaceText = (
    text: string,
    reference: string,
    replacingText: string,
  ) => {
    return text.replace(new RegExp(reference, "gi"), replacingText);
  };

  return (
    <NodeViewWrapper as="div">
      <div className="group">
        <div className="space-y-1 bg-red-50 relative">
          <div
            className={
              "bg-red-500 absolute -left-8 -top-2 opacity-90 rounded-full w-6 h-6 flex items-center justify-center text-white text-sm"
            }
          >
            {node.attrs.index + 1}
          </div>
          <p>{highlightReference(node.attrs.text, node.attrs.reference)}</p>
        </div>
        <div className="relative group-hover:opacity-90 opacity-0 transition-opacity duration-300">
          <div className="absolute z-10 flex justify-start space-x-2 bg-white rounded-md w-full">
            <Button
              text={"キャンセル"}
              color={"gray"}
              variant={"text"}
              size={"small"}
              className="border border-solid border-blueGray-50 text-xs"
              onClick={handleCancel}
            />
            <Button
              text={"置き換え"}
              color={"primary"}
              variant={"text"}
              size={"small"}
              className="border border-solid border-primary-400 text-xs"
              onClick={handleSave}
            />
          </div>
        </div>
      </div>
    </NodeViewWrapper>
  );
};
