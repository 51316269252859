import aspida, { FetchConfig } from "@aspida/fetch";

import { isLocalhost, spesillAIBaseURL } from "@spesill/config/env";
import api from "@spesill/models/api/$api";

// request intercepterを実装したい場合はここに定義する
const intercepteredFetch = async (
  input: RequestInfo | URL,
  init?: RequestInit | undefined,
): Promise<Response> => {
  // リクエスト時にidTokenを取得する
  const IdToken = isLocalhost() ? undefined : await getServerIdToken(input);

  const newInit: RequestInit = {
    ...init,
    headers: {
      ...init?.headers,
      Authorization: `Bearer ${IdToken}`,
    },
  };

  return fetch(input, newInit);
};

export async function getServerIdToken(input: URL | RequestInfo) {
  try {
    const url = `/api/server_token?input=${input}`;
    const response = await fetch(url);

    if (response.status !== 200) {
      throw new Error(
        `Failed to fetch server ID token: ${response.statusText}`,
      );
    }

    //  tokenを取得する
    return response.json().then((data) => data.token);
  } catch (error) {
    console.error("Error", error);
    throw error;
  }
}

export const apiClient = () => {
  const config: FetchConfig = {
    baseURL: spesillAIBaseURL(),
    throwHttpErrors: true,
  };

  return api(aspida(intercepteredFetch, config));
};
