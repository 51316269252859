import axios from "axios";
import React, { FC, MouseEvent, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";

import {
  Button,
  Icon,
  TextArea,
  TextField,
  Tooltip,
} from "@spesill/components/atoms";
import { IconButton } from "@spesill/components/molecules";

import { useCurrentUser, useFetchTenant } from "@spesill/hooks";
import { apiClient } from "@spesill/libs/apiClient";
import { FeedbackEnum } from "@spesill/models/api/register_feedback";
import { SourceType } from "@spesill/models/source";

import { DocumentAnswerSwitcher } from "./DocumentAnswerSwitcher";
import { DocumentSource } from "./DocumentSource";
import { cellIndexType } from "../../../DocumentEditor/DocumentExcelEditor/DocumentExcelEditor";

export type AnswerType = {
  responseId: string;
  spec: string;
  sources: SourceType[];
  isEvaluated?: boolean;
};

type PropsType = {
  className?: string;
  isRequesting: boolean;
  answers: AnswerType[];
  handleCancel: (e: MouseEvent<HTMLButtonElement>) => void;
  handleReflectInsertText: (value: string) => void;
  handleRegenerate: (e: MouseEvent<HTMLButtonElement>) => void;
  updateAnswer: (newItem: AnswerType, index: number) => void;
  isHtml?: boolean;
  isModalPortal?: boolean;
  excelOptions?: {
    setActiveCell: (cell: cellIndexType) => void;
    activeCell: cellIndexType | null;
    cellReferenceToCellIndex: (cell: string) => cellIndexType | undefined;
  };
};

export const DocumentAnswers: FC<PropsType> = ({
  isRequesting,
  answers,
  handleCancel,
  handleReflectInsertText,
  handleRegenerate,
  updateAnswer,
  className = "",
  isHtml = false,
  isModalPortal = false,
  excelOptions,
}: PropsType) => {
  const [displayIndex, setDisplayIndex] = useState<number>(0);
  const [editingText, setEditingText] = useState<string>("");
  const [celText, setCellText] = useState<string>(
    excelOptions?.activeCell?.cellText || "",
  );

  const { tenant } = useFetchTenant();
  const { currentUser } = useCurrentUser();

  const displayAnswer = useMemo(
    () => answers?.[displayIndex],
    [answers, displayIndex],
  );

  const handleCopy = () => {
    if (!navigator) return;
    navigator.clipboard
      .writeText(editingText || "")
      .then(() => toast.success("テキストをコピーしました"))
      .catch(() => toast.error("コピーに失敗しました"));
  };

  const handlePagination = (count: 1 | -1) => {
    let nextIndex = displayIndex + count;
    if (nextIndex < 0) {
      nextIndex = answers.length - 1;
    } else if (nextIndex >= answers.length) {
      nextIndex = 0;
    }
    setDisplayIndex(nextIndex);
  };

  const handleEvaluation = async (isGood: boolean) => {
    if (!displayAnswer) {
      return;
    }
    try {
      const res = await apiClient().register_feedback.$post({
        body: {
          target_response_id: displayAnswer.responseId,
          feedback: isGood ? FeedbackEnum.Good : FeedbackEnum.Bad,
        },
      });

      await axios.post("/api/ops_notification", {
        title: "回答の評価: " + (isGood ? "役に立った" : "役に立たなかった"),
        options: {
          tenant: tenant?.name,
          user: currentUser?.fullName,
          responseId: displayAnswer.responseId,
        },
        type: "success",
      });
      if (res.success) {
        toast.success("評価を送信しました。");
        updateAnswer({ ...displayAnswer, isEvaluated: true }, displayIndex);
      } else {
        toast.error(res.error || "評価の送信に失敗しました。");
      }
    } catch (error) {
      toast.error("評価の送信に失敗しました。");
    }
  };

  const handleExcelReflect = (text: string) => {
    if (!celText) {
      toast.error("セルを指定してください");
      return;
    }
    const position = excelOptions?.cellReferenceToCellIndex(celText);
    if (!position) {
      toast.error("セルを指定してください");
      return;
    }
    console.log(position);
    handleReflectInsertText(text);
  };

  useEffect(() => {
    if (!excelOptions?.activeCell?.cellText) return;
    setCellText(excelOptions?.activeCell?.cellText);
  }, [excelOptions?.activeCell?.cellText]);

  useEffect(() => {
    setDisplayIndex(answers.length - 1);
  }, [answers]);

  useEffect(() => {
    setEditingText(displayAnswer?.spec || "");
  }, [displayAnswer]);

  if (!displayAnswer) {
    return null;
  }

  return (
    <div className={`p-4 bg-white rounded-md shadow-lg w-full ${className}`}>
      {isHtml ? (
        <div
          contentEditable
          dangerouslySetInnerHTML={{ __html: displayAnswer.spec }}
          className="text-blueGray-500"
        />
      ) : (
        <div className="flex flex-col">
          <div className="flex items-end justify-end space-y-1.5">
            <IconButton
              icon={{
                icon: "mdContentCopy",
                size: "1.25rem",
                color: "text-blueGray-500",
              }}
              onClick={handleCopy}
              tooltip={{
                text: "コピーする",
                direction: "top",
                className: "text-xs",
              }}
            />
          </div>
          <TextArea
            name="insertText"
            value={editingText}
            placeholder="ここに文章を入力してください。"
            onChange={(e) => setEditingText(e.target.value)}
            rows={8}
            maxRows={20}
            className="!border-none !rounded-none"
          />
        </div>
      )}
      <DocumentSource
        sources={displayAnswer.sources}
        className="mt-4"
        isModalPortal={isModalPortal}
      />
      <div className="border-t border-blueGray-50 border-solid pt-4 mt-6">
        {isRequesting ? (
          <p className="text-blueGray-500 px-3 text-sm font-medium animate-pulse">
            生成中...
          </p>
        ) : (
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4 text-blueGray-500">
              {answers.length > 1 && (
                <DocumentAnswerSwitcher
                  currentIndex={displayIndex}
                  totalCount={answers.length}
                  onPageChange={handlePagination}
                />
              )}
              {!displayAnswer.isEvaluated && (
                <>
                  <div className="relative">
                    <Tooltip
                      direction="top"
                      text="役に立った"
                      style={{ left: "-0.75rem" }}
                    >
                      <button
                        type="button"
                        onClick={() => handleEvaluation(true)}
                        className="min-w-8 h-8 flex items-center justify-center hover:bg-blueGray-0 rounded"
                      >
                        <Icon
                          icon="mdOutlineThumbUp"
                          size="1.5rem"
                          color="text-blueGray-500"
                        />
                      </button>
                    </Tooltip>
                  </div>
                  <div className="relative">
                    <Tooltip
                      direction="top"
                      text="役に立たなかった"
                      style={{ left: "-0.75rem" }}
                    >
                      <button
                        type="button"
                        onClick={() => handleEvaluation(false)}
                        className="min-w-8 h-8 flex items-center justify-center hover:bg-blueGray-0 rounded"
                      >
                        <Icon
                          icon="mdOutlineThumbDown"
                          size="1.5rem"
                          color="text-blueGray-500"
                        />
                      </button>
                    </Tooltip>
                  </div>
                </>
              )}
              <button
                type="button"
                className="px-3 py-1 flex items-center space-x-1 hover:bg-primary-50 rounded-md"
                onClick={handleRegenerate}
              >
                <Icon icon="rxReload" size="1rem" color="text-primary-400" />
                <span className="text-primary-400">再生成</span>
              </button>
            </div>
            <div className="flex items-center space-x-4">
              <Button
                text="キャンセル"
                color="gray"
                variant="contained"
                className="whitespace-nowrap"
                onClick={handleCancel}
              />
              {excelOptions ? (
                <div className="flex items-center">
                  <TextField
                    type={"text"}
                    name={"cell"}
                    placeholder="反映するセルを指定"
                    value={celText}
                    onChange={(e) => setCellText(e.target.value)}
                    onBlur={() => {
                      const position =
                        excelOptions?.cellReferenceToCellIndex(celText);
                      if (!position) {
                        toast.error("セルを指定してください");
                        return;
                      }
                      excelOptions?.setActiveCell(position);
                    }}
                  />
                  <Button
                    text="反映"
                    color="primary"
                    variant="contained"
                    className="whitespace-nowrap"
                    onClick={() => handleExcelReflect(editingText)}
                  />
                </div>
              ) : (
                <Button
                  text="反映"
                  color="primary"
                  variant="contained"
                  onClick={() => handleReflectInsertText(editingText)}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
