import React, { useState } from "react";

import { FloatingMenu } from "@spesill/components/molecules";

import { ChatRoomMessage } from "@spesill/models";

interface TemplateSelectorProps {
  isSelectingTable: boolean;
  messages: ChatRoomMessage[];
  setValue: (value: string) => void;
}

const TemplateSelector: React.FC<TemplateSelectorProps> = ({
  isSelectingTable,
  messages,
  setValue,
}) => {
  const [isOpenTemplate, setIsOpenTemplate] = useState<boolean>(false);

  const templates = [
    "<項目名>に〇〇が含まれているデータの<項目名>を抽出して下さい",
    "<項目名>が〇〇の<項目名>を教えて下さい",
  ];

  if (!isSelectingTable) return null;

  return (
    <>
      <div className="pt-2 flex flex-row items-center gap-2">
        <span className="text-sm text-blueGray-500">
          可能な限りデータの項目名と同じ名前で指示するほうが精度が高まります。
        </span>
        {messages.length > 0 && (
          <div className="relative min-w-[12rem]">
            <button
              type="button"
              className="text-sm text-black-900 border border-blueGray-300 rounded-full px-4 py-1"
              onClick={() => setIsOpenTemplate(!isOpenTemplate)}
            >
              テンプレートを使用する
            </button>
            {isOpenTemplate && (
              <FloatingMenu
                onClose={() => setIsOpenTemplate(false)}
                className="right-0 bottom-10"
              >
                <div className="w-max">
                  {templates.map((template, index) => (
                    <button
                      key={index}
                      type="button"
                      className="block text-sm px-3 py-2 w-full text-left hover:bg-blueGray-0"
                      onClick={() => {
                        setValue(template);
                        setIsOpenTemplate(false);
                      }}
                    >
                      <p>
                        {template.split(/(<項目名>)/).map((part, i) =>
                          part === "<項目名>" ? (
                            <span key={i} className="text-primary-400">
                              {part}
                            </span>
                          ) : (
                            part
                          ),
                        )}
                      </p>
                    </button>
                  ))}
                </div>
              </FloatingMenu>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default TemplateSelector;
